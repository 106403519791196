import React from "react";
import { cdn } from "../../consts/cdn";
import { Helmet } from "react-helmet-async";

const bannerPaths = [
  "ContactUs/Banner-Desktop-Compressed.jpg",
  "ClinicalEvidence/Banner-Desktop-Compressed.jpg",
  "Events/Banner-Desktop-Compressed.jpg",
  "Home/Banner-Desktop-Compressed.jpg",
  "NotFound/Banner-Desktop-Compressed.jpg",
  "Patients/Banner-Desktop-Compressed.jpg",
  "PrivacyPolicy/Banner-Desktop-Compressed.jpg",
  "Redirect/Redirect_Banner_Desktop_EU_Compressed.jpeg",
  "Terms/Banner-Desktop-Compressed.jpg",
  "Testimonials/HCP/Banner-Desktop-Compressed.jpg",
  "Testimonials/Patients/Banner-Desktop-Compressed.jpg",
  "HealthcareProviders/Banner-Desktop-Compressed.jpg",
];

const PreloadBanners = () => {
  return (
    <Helmet>
      {bannerPaths.map((path, index) => (
        <link key={index} rel="preload" href={cdn.images + path} as="image" />
      ))}
    </Helmet>
  );
};

export default PreloadBanners;
