import React from "react";
import "../../../../../App.css";
import "../../../../_Shared/utility.css";
import "./Patient-testimonials.css";
import { EUBanner } from "../../../../Banner/Banner";
import { Helmet } from "react-helmet-async";
import TestimonalQuote from "../../../../TestimonalQuote/TestimonalQuote";
import { cdn } from "../../../../../consts/cdn";
import { JwPlayer } from "../../../../JwPlayer/JwPlayer.js";

//images
const VideoThumbnail1 =
  cdn.images +
  "Testimonials/Patients/638653-EN-A vNOTES Hysterectomy A Patient's Perspective on Recovery_Thumbnail.jpg";
const VideoThumbnail2 =
  cdn.images +
  "Testimonials/Patients/638655-EN-A Ready to Live Life Again with vNOTES_Thumbnail.jpg";
const VideoThumbnail3 =
  cdn.images +
  "Testimonials/Patients/638650-EN-A vNOTES Hysterectomy A Patient's Perspective on Pain_Thumbnail.jpg";
const BannerDesktop =
  cdn.images + "Testimonials/Patients/Banner-Desktop-Compressed.jpg";
const BannerMobile = cdn.images + "Testimonials/Patients/Banner-Mobile.jpg";

function PatientTestimonial() {
  return (
    <>
      <Helmet>
        <title>vNOTES Hysterectomy</title>
        <meta
          name="description"
          content="vNOTES (vaginal natural orifice transluminal endoscopic surgery) is another minimally invasive approach to consider for your hysterectomy and other gynecologic…"
        />
      </Helmet>
      <div className="testimonial">
        <EUBanner
          imgdesktop={BannerDesktop}
          imgmobile={BannerMobile}
          bold1="TESTIMONIALS"
          text1="Patients"
          color="#365B7E"
          openSans="true"
        />
        <div className="content">
          <div className="patient__video-container patient__video-container--1">
            <div className="testimonial__content--1">
              <JwPlayer
                file="https://ep-appliedmediaservice-ammedia-mediakind.westus2.streaming.mediakind.com/b0304774-c3de-4521-aa34-da96c61e7fea/638653-EN-A vNOTES Hysterectomy .ism/manifest(format=m3u8-cmaf)"
                title="vNOTES Hysterectomy: A Patient's Perspective on Recovery"
                image={VideoThumbnail1}
              ></JwPlayer>
              <div className="video-description">
                <p>vNOTES Hysterectomy: A Patient's Perspective on Recovery</p>
              </div>
            </div>
          </div>

          <div className="patient__video-container patient__video-container--2">
            <div className="testimonial__content--2">
              <JwPlayer
                file="https://ep-appliedmediaservice-ammedia-mediakind.westus2.streaming.mediakind.com/47810073-9f08-448a-96b8-15a04652a04e/638655-EN-A Ready to Live Life A.ism/manifest(format=m3u8-cmaf)"
                title="Ready to Live Life Again with vNOTES"
                image={VideoThumbnail2}
              ></JwPlayer>
              <div className="video-description">
                <p>Ready to Live Life Again with vNOTES</p>
              </div>
            </div>

            <div className="testimonial__content--2">
              <JwPlayer
                file="https://ep-appliedmediaservice-ammedia-mediakind.westus2.streaming.mediakind.com/fc4deb81-a65a-4857-b6bb-10c285c75c5e/638650-EN-A vNOTES Hysterectomy .ism/manifest(format=m3u8-cmaf)"
                title="vNOTES Hysterectomy: A Patient's Perspective on Pain"
                image={VideoThumbnail3}
              ></JwPlayer>
              <div className="video-description">
                <p>vNOTES Hysterectomy: A Patient's Perspective on Pain</p>
              </div>
            </div>
          </div>

          <div className="patients__div-review" id="first-patient-testimonial">
            <TestimonalQuote
              quote="I was really surprised when I was in recovery, because I could get
          up on my own and I could move and go to the restroom. The little
          things you don't expect you would be able to do for a while, I was
          able to do very, very quickly, I mean right after surgery."
            />
          </div>

          <div className="patients__div-review">
            <TestimonalQuote quote="The vNOTES procedure and my surgeon made it possible for me to just have a little blip on life and just keep on going, and I feel so much better after the procedure!" />
          </div>
        </div>
      </div>
    </>
  );
}

export default PatientTestimonial;
