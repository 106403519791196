import React from "react";
import "../../../../App.css";
import "../../../_Shared/utility.css";
import "./NotFound.css";
import { EUBanner } from "../../../Banner/Banner";
import { cdn } from "../../../../consts/cdn";

//Images
const BannerDesktop = cdn.images + "NotFound/Banner-Desktop-Compressed.jpg";
const BannerMobile = cdn.images + "NotFound/Banner-Mobile.jpg";

function NotFound() {
  return (
    <>
      <div className="notFound-EU">
        <EUBanner
          imgdesktop={BannerDesktop}
          imgmobile={BannerMobile}
          bold1="404"
          text1="We cannot seem to find the page"
          text2="that you are looking for."
          button1="Go Back Home"
          route1="/eu"
          color="#365B7E"
          openSans="true"
        />
      </div>
    </>
  );
}

export default NotFound;
