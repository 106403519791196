import React from "react";
import "../../../../../App.css";
import "../../../../_Shared/utility.css";
import "./HCP-testimonials.css";
import "../Patients/Patient-testimonials.css";
import { TestimonalQuote } from "../../../../TestimonalQuote/TestimonalQuote";
import { EUBanner } from "../../../../Banner/Banner";
import { Helmet } from "react-helmet-async";
import { cdn } from "../../../../../consts/cdn";
import { JwPlayer } from "../../../../JwPlayer/JwPlayer.js";

//images
const VideoThumbnail1 = cdn.images + "Testimonials/HCP/Thumbnail1.jpg";
const VideoThumbnail2 =
  cdn.images + "Testimonials/HCP/HCPTestimonials_Thumbnail1_EU.jpg";
const VideoThumbnail3 =
  cdn.images + "Testimonials/HCP/HCPTestimonials_Thumbnail2_EU.jpg";
const BannerDesktop =
  cdn.images + "Testimonials/HCP/Banner-Desktop-Compressed.jpg";
const BannerMobile = cdn.images + "Testimonials/HCP/Banner-Mobile.jpg";

function HCPTestimonial() {
  return (
    <>
      <Helmet>
        <title>
          vNOTES – Advanced Minimally Invasive Gynecologic Procedures
        </title>
        <meta
          name="description"
          content="vNOTES (vaginal natural orifice transluminal endoscopic surgery) workshops provide a comprehensive understanding of procedural steps and instrumentation used…"
        />
      </Helmet>
      <div className="testimonial">
        <EUBanner
          imgdesktop={BannerDesktop}
          imgmobile={BannerMobile}
          bold1="TESTIMONIALS"
          text1="Healthcare Professionals"
          color="#365B7E"
          openSans="true"
        />
        <div className="content">
          <div className="hcp__video-container hcp__video-container--1">
            <div className="testimonial__content--1">
              <JwPlayer
                file="https://ep-appliedmediaservice-ammedia-mediakind.westus2.streaming.mediakind.com/3b0e820b-1207-4b12-aec9-6d9ac7159df6/vNOTES as Featured on ACOG TV.ism/manifest(format=m3u8-cmaf)"
                title="vNOTES as Featured on ACOG TV"
                image={VideoThumbnail1}
              ></JwPlayer>
              <div className="video-description">
                <p>vNOTES as Featured on ACOG TV</p>
              </div>
            </div>
          </div>

          <div className="hcp__video-container hcp__video-container--2">
            <div className="testimonial__content--2">
              <JwPlayer
                file="https://ep-appliedmediaservice-ammedia-mediakind.westus2.streaming.mediakind.com/87045b6f-5b76-4c8b-b269-ceec9ae135ea/436071-EN-EUR-A_vNOTES A Surgeon.ism/manifest(format=m3u8-cmaf)"
                title="vNOTES: A Surgeon's Perspective on Procedural Benefits"
                image={VideoThumbnail2}
              ></JwPlayer>
              <div className="video-description">
                <p>vNOTES: A Surgeon's Perspective on Postoperative Pain</p>
              </div>
            </div>

            <div className="testimonial__content--2">
              <JwPlayer
                file="https://ep-appliedmediaservice-ammedia-mediakind.westus2.streaming.mediakind.com/5f828916-3d6b-4487-b137-5151b425e1ac/484718-EN-A vNOTES A Vaginal Sur.ism/manifest(format=m3u8-cmaf)"
                title="vNOTES: A PACU Nurse's Perspective on Patient Recovery"
                image={VideoThumbnail3}
              ></JwPlayer>
              <div className="video-description">
                <p>vNOTES: A Vaginal Surgeon's Preference</p>
              </div>
            </div>
          </div>

          <div className="hcp__div-review">
            <TestimonalQuote
              quote="We have found that with vNOTES patients recover more quickly and have less postoperative pain. Additionally, the OR time for the procedure is shorter and results in fewer postoperative complications."
              name="Jan Baekelandt,&nbsp;"
              credential="MD, PhD"
              location="Belgium"
            />
          </div>

          <div className="hcp__div-review">
            <TestimonalQuote
              quote="vNOTES has helped my patients return to everyday life quickly by significantly reducing their postoperative pain. Additionally, patients appreciate not having any visible scars because they feel better about their appearance after surgery."
              name="Grover May,&nbsp;"
              credential="MD"
              location="United States"
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default HCPTestimonial;
