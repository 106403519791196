import React from "react";
import "../../../../App.css";
import "./Home.css";
import { EUBanner } from "../../../Banner/Banner";
import ReactTooltip from "react-tooltip";
import { cdn } from "../../../../consts/cdn";

//Images
const BannerDesktop = cdn.images + "Home/Banner-Desktop-Compressed.jpg";
const BannerMobile = cdn.images + "Home/Banner-Mobile.jpg";

function Home() {
  return (
    <>
      <div className="home">
        <EUBanner
          imgdesktop={BannerDesktop}
          imgmobile={BannerMobile}
          bold1="vNOTES"
          text1="The Next Evolution in Minimally"
          text2="Invasive Gynecologic Surgery"
          button1="Why vNOTES"
          route1="/eu/whyvnotes"
          button2="Patient Resources"
          route2="/eu/patientresources"
          color="#365B7E"
        />
        <ReactTooltip />
      </div>
    </>
  );
}

export default Home;
