import React, { useState } from "react";
import "../../../../../App.css";
import "../../../../_Shared/utility.css";
import "../../../../ClinicalStudiesTile/ClinicalStudiesTile";
import { EUBanner } from "../../../../Banner/Banner";
import { Helmet } from "react-helmet-async";
import studies from "./Studies";
import "./ClinicalStudies.css";
import ClinicalStudiesTile from "../../../../ClinicalStudiesTile/ClinicalStudiesTile";
import { cdn } from "../../../../../consts/cdn";
import ExternalLinkModal from "../../../../Modals/ExternalLink/ExternalLinkModal";

// Images
const BannerDesktop =
  cdn.images + "ClinicalEvidence/Banner-Desktop-Compressed.jpg";
const BannerMobile = cdn.images + "ClinicalEvidence/Banner-Mobile.jpg";

export const ClinicalStudies = () => {
  const [showModal, setShowModal] = useState(false);
  const [externalUrl, setExternalUrl] = useState("");

  const handleStudyClick = (url) => {
    try {
      const currentDomain = window.location.hostname;
      const linkDomain = new URL(url).hostname;

      if (linkDomain !== currentDomain) {
        setExternalUrl(url);
        setShowModal(true);
      } else {
        // Navigate to internal link directly
        window.location.href = url;
      }
    } catch (error) {
      console.error("Invalid URL:", url);
    }
  };

  const proceedToExternalLink = () => {
    setShowModal(false);
    window.open(externalUrl, "_blank");
  };

  return (
    <div className="clinicalstudies">
      <Helmet>
        <title>
          vNOTES – Advanced Minimally Invasive Gynecologic Procedures
        </title>
        <link rel="canonical" href="https://www.vnotes.com/clinicalevidence" />
        <meta
          name="description"
          content="vNOTES (vaginal natural orifice transluminal endoscopic surgery) workshops provide a comprehensive understanding of procedural steps and instrumentation used…"
        />
      </Helmet>
      <div className={`EUBanner ${showModal ? "blur" : ""}`}>
        <EUBanner
          imgdesktop={BannerDesktop}
          imgmobile={BannerMobile}
          bold1="CLINICAL EVIDENCE"
          color="#365B7E"
        />
      </div>

      <div className={`content ${showModal ? "blur" : ""}`}>
        {studies.map((study) => (
          <ClinicalStudiesTile
            key={study.uniqueid}
            header={study.header}
            date={study.date}
            boldtext={study.boldtext}
            doctors={study.doctors}
            content={study.content}
            studyurl={study.studyurl}
            uniqueid={study.uniqueid}
            onExternalLinkClick={handleStudyClick} // Pass the handler
          />
        ))}
      </div>

      <ExternalLinkModal
        show={showModal}
        onClose={() => setShowModal(false)}
        onProceed={proceedToExternalLink}
      />
    </div>
  );
};

export default ClinicalStudies;
