import "./App.css";
import React, { useEffect, useState } from "react";
import EULayout from "../src/regionLayouts/EULayout";
import USLayout from "../src/regionLayouts/USLayout";
import { HelmetProvider } from "react-helmet-async";
import { BrowserRouter as Router } from "react-router-dom";

function App() {
  const [region, setRegion] = useState(null);

  useEffect(() => {
    fetch("https://icanhazip.com/")
      .then((response) => response.text())
      .then((ip) => {
        const trimmedIp = ip.trim();
        console.log("Fetched IP:", trimmedIp);

        fetch(
          `https://api.appliedmed.com/GeoLocation/api/GetUserIPAddressHCPInformation?ip=${trimmedIp}&ApiKey=${process.env.REACT_APP_SECRET_NAME}`
        )
          .then((response) => response.json())
          .then((data) => {
            console.log("API Response:", JSON.stringify(data));

            if (data && data.Results && data.Results.countryName) {
              const { countryName } = data.Results;
              const region = determineRegion(countryName);
              setRegion(region);
            } else {
              console.error("Unexpected API response format:", data);
              setRegion("US");
            }
          })
          .catch((error) => {
            console.error("Error fetching from API:", error);
            setRegion("US");
          });
      })
      .catch((error) => {
        console.error("Error fetching IP:", error);
        setRegion("US");
      });
  }, []);

  const determineRegion = (countryName) => {
    const euCountries = [
      "Germany",
      "France",
      "Spain",
      "Italy",
      "The Netherlands",
      "Belgium",
      "Sweden",
      "Finland",
      "Poland",
      "Austria",
      "Switzerland",
      "Ireland",
      "Portugal",
      "Norway",
      "Denmark",
      "United Kingdom",
      "Luxembourg",
    ];

    if (euCountries.includes(countryName)) {
      console.log("EU");
      return "EU";
    } else {
      console.log("US");
      return "US";
    }
  };

  if (region === null) {
    return <div></div>; // Display while fetching region
  }
  return (
    <HelmetProvider>
      <Router>
        {region === "EU" ? (
          <EULayout region={region} />
        ) : (
          <USLayout region={region} />
        )}
      </Router>
    </HelmetProvider>
  );
}

export default App;
