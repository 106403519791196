import React, { useState } from "react";
import "./Events.css";
import "../../../../../App.css";
import "../../../../_Shared/utility.css";
import EventsTile from "../../../../EventsTiles/EventTiles";
import { EUBanner } from "../../../../Banner/Banner";
import { Helmet } from "react-helmet-async";
import { cdn } from "../../../../../consts/cdn";
import ExternalLinkModal from "../../../../Modals/ExternalLink/ExternalLinkModal";

//Images
const BannerDesktop = cdn.images + "Events/Banner-Desktop-Compressed.jpg";
const BannerMobile = cdn.images + "Events/Banner-Mobile.jpg";

function Events() {
  const [showModal, setShowModal] = useState(false);
  const [externalUrl, setExternalUrl] = useState("");

  const handleStudyClick = (url) => {
    try {
      const currentDomain = window.location.hostname;
      const linkDomain = new URL(url).hostname;

      if (linkDomain !== currentDomain) {
        setExternalUrl(url);
        setShowModal(true);
      } else {
        // Navigate to internal link directly
        window.location.href = url;
      }
    } catch (error) {
      console.error("Invalid URL:", url);
    }
  };

  const proceedToExternalLink = () => {
    setShowModal(false);
    window.open(externalUrl, "_blank");
  };

  return (
    <div className="events">
      <Helmet>
        <title>
          vNOTES – Advanced Minimally Invasive Gynecologic Procedures
        </title>
        <meta
          name="description"
          content="vNOTES (vaginal natural orifice transluminal endoscopic surgery) workshops provide a comprehensive understanding of procedural steps and instrumentation used…"
        />
      </Helmet>
      <div className={`EUBanner ${showModal ? "blur" : ""}`}>
        <EUBanner
          imgdesktop={BannerDesktop}
          imgmobile={BannerMobile}
          bold1="EVENTS"
          color="#365B7E"
        />
      </div>

      <div className={`content ${showModal ? "blur" : ""}`}>
        <EventsTile
          date="May 2024"
          title="vNOTES Full Circle: From Physician to Patient"
          content="Join Drs. Elizabeth Baker, Elizabeth Boggs and Brandi Hardin as they present procedural footage and discuss why vNOTES is their preferred technique. With varied backgrounds, from traditional vaginal surgery to robotics, each surgeon will share how vNOTES increases access and visibility for complex procedures and improves patient outcomes. Dr. Boggs will also speak to her experience as a vNOTES patient herself and how her quick recovery allowed her to return to the OR within two weeks."
          doctors="Drs. Elizabeth Baker, Elizabeth Boggs and Brandi&nbsp;Hardin"
          webinar
          pastevent
          comingsoonurl
          onExternalLinkClick={handleStudyClick} // Pass the handler
        />
        <EventsTile
          date="October 2023"
          title="vNOTES Webinar Series: Progressing Your Practice"
          content="Join us as Drs. Jean Dubuisson, Géraldine Giraudet and Grover May share how they have advanced their practices with vNOTES. Observe case presentations and engage in a live Q&A on how to approach complex cases, such as adnexal procedures and the removal of large uteri, with the vNOTES approach."
          doctors="Drs. Jean Dubuisson, Géraldine Giraudet and Grover May"
          webinar
          eventurl="https://vablet.com/s/wZQ7vko"
          onExternalLinkClick={handleStudyClick} // Pass the handler
        />
        <EventsTile
          date="September 2023"
          title="Visibility and Accessibility: Why vNOTES Makes Sense in My Practice"
          content="In this AUGS-sponsored webinar, Drs. Cheryl Iglesia, Fiona Lindo, Thomas Pitman and Dani Zoorob will share why they choose the vNOTES approach for their urogynecologic procedures. Observe video case presentations to see first-hand how vNOTES improves vaginal access and visualization. Additionally, learn from the panel’s real-world experiences and patient outcomes when using this innovative approach."
          doctors="Drs. Cheryl Iglesia, Fiona Lindo, Thomas Pitman, and Dani Zoorob"
          webinar
          eventurl="https://www.youtube.com/watch?v=RfV-H-bgGIE"
          onExternalLinkClick={handleStudyClick} // Pass the handler
        />
        <EventsTile
          date="May 2023"
          title="vNOTES Webinar Series: vNOTES Hysterectomy Foundations"
          content="Join us as Drs. Asha Bhalwal, Brandi Hardin and Jay Matkins share the fundamentals of vNOTES. Engage in a live Q&A with the panelists as they review a complete vNOTES hysterectomy, including patient selection, OR and GelPOINT™ V-Path platform setup, colpotomy creation, postoperative care and patient outcomes."
          extra="Whether you’re new to vNOTES or curious to know more, attend this webinar to learn what is involved in a vNOTES hysterectomy and how this procedure can benefit you and your patients."
          doctors="Drs. Asha Bhalwal, Brandi Hardin and Jay Matkins"
          webinar
          eventurl="https://vablet.com/s/ioPn1jf"
          onExternalLinkClick={handleStudyClick} // Pass the handler
        />
        <EventsTile
          date="October 2022"
          title="Performing Adnexectomy with vNOTES"
          content="Learn why Dr. Giraudet has adopted vNOTES, and watch as she shares adnexectomy case videos and explains the benefits of the technique for this procedural application."
          doctor=" Dr. Géraldine Giraudet"
          symposium
          eventurl="https://vablet.com/s/QrHiqOC"
          onExternalLinkClick={handleStudyClick} // Pass the handler
        />
        <EventsTile
          date="October 2022"
          title="Repairing Prolapse with vNOTES"
          content="Watch Dr. Jean Dubuisson as he presents vNOTES prolapse repair case videos, shares the latest clinical evidence, and describes the benefits of adopting this innovative approach."
          doctor=" Dr. Jean Dubuisson"
          symposium
          eventurl="https://vablet.com/s/AIhEnPF"
          onExternalLinkClick={handleStudyClick} // Pass the handler
        />
        <EventsTile
          date="October 2021"
          title="vNOTES: Broaden your Horizons of Vaginal Surgery"
          content="Join our panel of expert surgeons from across Europe as they share their experiences with the vNOTES technique. Panelists will present vNOTES adnexectomy and hysterectomy videos, followed by a discussion of the benefits, indications and clinical evidence."
          doctors=" Mr. Oudai Ali and Drs. Jan Baekelandt, Martine Wassen, Géraldine Giraudet and Jean Dubuisson"
          symposium
          eventurl="https://vablet.com/s/sb5exPv"
          onExternalLinkClick={handleStudyClick} // Pass the handler
        />
        <EventsTile
          date="February 2021"
          title="vNOTES: Modernizing Vaginal Surgery"
          content="Listen as experts discuss the evolution of vNOTES, and share videos that provide 
insights into the clinical benefits, indications and training pathway of this novel
 approach."
          doctors=" Drs. Jan Baekelandt, Jean Dubuisson, Susanne Housmans, Martine Wassen, Nicol Smeets and Aslam Mansoor"
          webinar
          eventurl="https://vablet.com/s/LsMLrjC"
          onExternalLinkClick={handleStudyClick} // Pass the handler
        />
        <EventsTile
          date="December 2020"
          title="vNOTES Hysterectomy of a Large Uterus"
          content="Watch a step-by-step demonstration of the vNOTES hysterectomy technique for a patient with a large uterus. Learn about the indications for vNOTES and tips and tricks to increase surgical success."
          doctor=" Dr. Jan Baekelandt "
          symposium
          eventurl="https://vablet.com/s/AOS695B"
          onExternalLinkClick={handleStudyClick} // Pass the handler
        />
      </div>
      <ExternalLinkModal
        show={showModal}
        onClose={() => setShowModal(false)}
        onProceed={proceedToExternalLink}
      />
    </div>
  );
}

export default Events;
