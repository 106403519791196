import React, { useRef, useEffect, useContext } from "react";
import { useHistory, useLocation } from "react-router-dom";
import "../../../../App.css";
import "../../../_Shared/utility.css";
import "./HCPModal.css";
import { Button } from "../../../Button/Button";
import { ModalContext } from "../../../../regionLayouts/EULayout";

function HCPModal() {
  const [isHCPModalVisible, setIsHCPModalVisible] = useContext(ModalContext);
  const yesRadioButtonRef = useRef(null);
  const noRadioButtonRef = useRef(null);
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    const contentPages = [
      "/eu",
      "/eu/clinicalevidence",
      "/eu/events",
      "/eu/whyvnotes",
      "/eu/patientresources",
      "/eu/patientstestimonials",
      "/eu/hcptestimonials",
      "/eu/contactus",
      "/eu/privacypolicy",
      "/eu/legalnotice",
    ];

    const refPages = ["/eu/clinicalevidence/", "/eu/whyvnotes/"];

    const contentPathRegex = new RegExp(
      `^(${contentPages.join("|")})(/)?$`,
      "i"
    );
    const refPathRegex = new RegExp(`^(${refPages.join("|")})`, "i");

    const contentPathMatch = contentPathRegex.test(location.pathname);
    const refPathMatch = refPathRegex.test(location.pathname);

    if (
      sessionStorage.getItem("isHCP") !== "yes" &&
      (contentPathMatch || refPathMatch)
    ) {
      setIsHCPModalVisible(true);
    }

    if (isHCPModalVisible) {
      yesRadioButtonRef.current.checked = false;
      noRadioButtonRef.current.checked = false;
    }
  }, [isHCPModalVisible, setIsHCPModalVisible, location.pathname]);

  function onConfirmSelection() {
    if (yesRadioButtonRef.current.checked || noRadioButtonRef.current.checked) {
      if (yesRadioButtonRef.current.checked) {
        sessionStorage.setItem("isHCP", "yes");
      } else if (noRadioButtonRef.current.checked) {
        sessionStorage.setItem("isHCP", "no");
        history.push("/eu/redirect");
      }

      setIsHCPModalVisible(false);
    }
  }

  return (
    <div
      className={
        isHCPModalVisible
          ? "hcp-modal-background active"
          : "hcp-modal-background"
      }
    >
      <div className="hcp-modal-container">
        <p className="hcp-modal-header">Are you a healthcare professional?</p>

        <div className="hcp-disclaimer">
          <p>
            The information on the page you are about to enter is intended for
            healthcare professionals only.{" "}
          </p>
          <br />
          <p>
            By selecting "Yes" and clicking on the button below, you confirm
            that you are a healthcare professional.
          </p>
        </div>

        <div className="hcp-modal-options-container">
          <div className="radio-button-container">
            <input
              type="radio"
              id="yes"
              name="hcp"
              value="yes"
              ref={yesRadioButtonRef}
            />
            <label className="selection-label" htmlFor="yes">
              Yes, I am a healthcare professional.
            </label>
          </div>
          <div className="radio-button-container">
            <input
              type="radio"
              id="no"
              name="hcp"
              value="no"
              ref={noRadioButtonRef}
            />
            <label className="selection-label" htmlFor="no">
              No, I am not a healthcare professional.
            </label>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "30px",
          }}
        >
          <Button onClick={onConfirmSelection}>CONFIRM</Button>
        </div>
      </div>
    </div>
  );
}

export default HCPModal;
