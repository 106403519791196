import React from "react";
import "../../../../App.css";
import "../../../_Shared/utility.css";
import "./Redirect.css";
import { EUBanner } from "../../../Banner/Banner";
import { cdn } from "../../../../consts/cdn";

const BannerDesktop =
  cdn.images + "Redirect/Redirect_Banner_Desktop_EU_Compressed.jpeg";
const BannerMobile = cdn.images + "Redirect/Redirect_Banner_Mobile.png";

function Redirect() {
  return (
    <>
      <div className="redirect-EU">
        <EUBanner
          imgdesktop={BannerDesktop}
          imgmobile={BannerMobile}
          openSans="true"
          text1="This website is only intended for"
          text2="healthcare professionals."
          color="#365B7E"
          redirectSubtitle="If you are a patient, please speak with your healthcare provider to learn more about vNOTES."
        />
      </div>
    </>
  );
}

export default Redirect;
